import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import profileImg from "../../assets/images/profileImg.png";
import R from "../../assets/images/R.jpeg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TimeZones } from "src/utils/constants";
import { EditProfileApi, memberDetail } from "src/DAL/Profile/Profile";
import { makeStyles } from "@mui/styles";
import { Autocomplete, CircularProgress } from "@mui/material";
import countryList from "react-select-country-list";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { profilePlaceholder } from "src/assets";
const Input = styled("input")({
  display: "none",
});
TimeZones.sort();
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

function EditProfile(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = React.useState("");
  const [image, setImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeDate = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        dob: event?.$d,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getMemberDetail = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setMemberData(result.member);
      console.log(result.member, "result.member");
      setTimeZoneValue(result.member.time_zone);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    var dateString = moment(memberData.dob).format("YYYY-MM-DD");
    let [year, month, day] = [1, 1, 1];

    if (dateString !== "Invalid date") {
      [year, month, day] = dateString.split("-");
      console.log(day, month, year, "seperated year month day");
      if (year < 1900 || year > moment().year()) {
        enqueueSnackbar("Please select a valid year", { variant: "error" });
      } else {
        setIsLoading(true);
        const formData = new FormData();
        var dateString = moment(memberData.dob).format("YYYY-MM-DD");
        formData.append("first_name", memberData.first_name);
        formData.append("last_name", memberData.last_name);
        formData.append("street", memberData.street);
        formData.append("city", memberData.city);
        formData.append("zip_code", memberData.zip_code);
        formData.append("state", memberData.state);
        formData.append("country", memberData.country);
        formData.append("time_zone", timeZoneValue);
        formData.append("facebook_link", memberData.facebook_link);
        formData.append("website_link", memberData.website_link);
        formData.append("instagram_link", memberData.instagram_link);
        formData.append("linkedin_link", memberData.linkedin_link);
        formData.append("youtube_link", memberData.youtube_link);
        formData.append("member_biography", memberData.member_biography);
        formData.append("contact_number", memberData.contact_number);
        formData.append(
          "nineteen_day_plan_currency",
          memberData.nineteen_day_plan_currency
        );
        formData.append("dob", dateString);
        if (image) {
          formData.append("profile_image", image);
        }
        console.log(...formData);
        const result = await EditProfileApi(formData);
        if (result.code === 200) {
          localStorage.setItem(
            `user_data`,
            JSON.stringify(result.existingMember)
          );
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Please select a valid date", { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  useEffect(() => {
    getMemberDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container ">
      <form onSubmit={handleUpdate}>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className=" image d-flex flex-column justify-content-center align-items-center">
              <div className="edit-profile-icon">
                <img
                  src={
                    previews
                      ? previews
                      : memberData.profile_image
                      ? s3baseUrl + memberData.profile_image
                      : profilePlaceholder
                  }
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
              <div className="row w-100 mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="first_name"
                      value={memberData.first_name}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.last_name}
                      name="last_name"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.contact_number}
                      name="contact_number"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mui-date-picker-box">
                  <div className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of birth"
                        name="dob"
                        className="mui-date-picker"
                        value={memberData.dob}
                        format="DD/MM/YYYY"
                        mask="__/__/____"
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => handleChangeDate(e)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            sx={{
                              input: {
                                color: get_root_value("--input-text-color"),
                              },
                            }}
                            required={true}
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="street"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.street}
                      name="street"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.state}
                      name="state"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Town or City"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.city}
                      name="city"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4 select-box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Select
                        className="svg-color"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={memberData.country}
                        name="country"
                        label="Country"
                        MenuProps={{
                          classes: {
                            paper: classes.paper,
                          },
                        }}
                        onChange={handleChange}
                        size="small"
                        sx={{
                          color: get_root_value("--input-text-color"),
                        }}>
                        {options.map((option_value, i) => {
                          return (
                            <MenuItem value={option_value.value}>
                              {option_value.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Area Code"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.zip_code}
                      name="zip_code"
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <Autocomplete
                    value={timeZoneValue}
                    onChange={(event, newValue) => {
                      setTimeZoneValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={TimeZones}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: "#fff" },
                          input: { color: "#fff" },
                        }}
                        {...params}
                        label="Time Zone *"
                        size="small"
                      />
                    )}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-controlled-open-select-label">
                        Currency for 90 Day Tracker
                      </InputLabel>
                      <Select
                        MenuProps={{
                          classes: {
                            paper: classes.paper,
                          },
                        }}
                        className="svg-color"
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={memberData.nineteen_day_plan_currency}
                        name="nineteen_day_plan_currency"
                        label="Currency for 90 Day Tracker"
                        onChange={(e) => handleChange(e)}
                        size="small"
                        sx={{
                          color: get_root_value("--input-text-color"),
                        }}>
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="eur">Euro</MenuItem>
                        <MenuItem value="gbp">Pond</MenuItem>
                        <MenuItem value="usd">Dollar</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4 textarea-block">
                    <TextField
                      id="outlined-basic"
                      label="Biography"
                      variant="outlined"
                      rows={4}
                      multiline
                      fullWidth
                      size="small"
                      value={memberData.member_biography}
                      name="member_biography"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <h3>Social Links</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Facebook link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.facebook_link}
                      name="facebook_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Linkedin Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.linkedin_link}
                      name="linkedin_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Website Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.website_link}
                      name="website_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Instagram Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.instagram_link}
                      name="instagram_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Youtube Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.youtube_link}
                      name="youtube_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                <span className="upload-button mt-3">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file" className="w-100">
                    <CloudUploadIcon />
                  </label>
                </span>
              </div>
            </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                {previews &&
                  previews.map((file, index) => (
                    <div className="col-3 mt-3" key={index}>
                      <div className="preview">
                        <span onClick={() => handleRemove(index)}>x</span>
                        <img src={file} />
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
              </div>

              <div className="mt-2 ms-auto">
                <button className="small-contained-button mt-4 ">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
